import { Button } from "@fefyi/ui/button";
import { Tile } from "./tile";
import { FramerLogo } from "@fefyi/ui/astro/icons/framer-logo";

export const SubnavCourses = () => (
  <>
    <div className="md:hidden">
      <Tile
        title="Framer Motion"
        subtitle=""
        href="/course/framer-motion"
        icon={FramerLogo}
      />
    </div>
    <div className="grid grid-cols-[repeat(2,300px)] gap-2 max-md:hidden">
      <div>
        <a
          href="/course/framer-motion"
          className="block rounded-2xl outline-none ring-white focus-visible:ring-2"
        >
          <img
            src="/course/static/og-framer-motion.webp"
            alt="Framer Motion Course"
            width={600}
            height={315}
            className="h-auto w-full rounded-2xl"
          />
        </a>
      </div>
      <div className="my-auto">
        <a
          href="/course/framer-motion"
          className="group mb-3 block rounded-md text-lg leading-tight outline-none"
        >
          <strong className="text-xl group-focus-visible:underline">
            Latest course
          </strong>
          <br />
          <span className="block [white-space:balance]">
            Animating the web with Framer Motion
          </span>
        </a>
        <div className="flex gap-2 max-md:justify-center">
          <Button href="/course/framer-motion" size="small">
            Go to course
          </Button>
          <Button href="https://www.fantasticframermotion.fyi" target="_blank" size="small" intent="white">
            Learn more
          </Button>
        </div>
      </div>
    </div>
  </>
);
